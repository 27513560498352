<template>
  <div id="ag-grid-demo">
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <b-card>
        <!-- TABLE ACTION ROW -->
        <div class="d-flex flex-wrap justify-content-between my-0">

          <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <b-row
            class="w-100"
            :class="{'mt-1': isMobile}"
          >

            <b-col
              cols="12"
              md="10"
            >
              <b-form-input
                v-model="searchQuery"
                class="md:mb-0 mr-4"
                :class="{'mb-1': isMobile, 'mb-0': !isMobile}"
                placeholder="Search..."
                @input="updateSearchQuery"
              />
            </b-col>
            <b-col
              cols="12"
              md="2"
            >
              <b-button
                variant="primary"
                class="mb-1 md:mb-0 w-100"
                @click="gridApi.exportDataAsCsv()"
              >Export as CSV</b-button>
            </b-col>

          </b-row>
        </div>

        <vue-good-table
          mode="remote"
          :pagination-options="{
            enabled: true,
            perPage: serverParams.perPage,
            perPageDropdown: [5, 10, 20, 50, 100],
            dropdownAllowAll: false,
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: false, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :is-loading.sync="isLoading"
          :total-rows="totalRecords"
          :rows="records"
          :columns="columnDefs"

          styleClass="vgt-table condensed bordered"
          compactMode
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
        >

          <template
            slot="column-filter"
            slot-scope="props"
          >
            <span
              v-if="props.column.field === 'created_on'"
              class="mt-2"
            >
              <!-- <flat-pickr
                v-model="created_on_filter"
                :config="configPicker"
                class="vgt-input"
                placeholder="Filter Added On"
                @input="(value) => updateFilters(props.column.field, value)"
              /> -->

              <b-button
                variant="warning"
                @click="showDateRangeFilter(true, props.column.field)"
              >
                Click to filter the date
              </b-button>
              <b-button
                v-if="props.column.field in serverParams.columnFilters"
                class="ml-2"
                @click="resetDateRangeFilter(true, props.column.field)"
              >Reset</b-button>
            </span>
          </template>

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Status -->
            <span v-if="props.column.field === 'account_approved'">
              <CellRendererStatus :params="{account_approved: props.row.account_approved}" />
            </span>
            <span v-else-if="props.column.field === 'account_approval_status'">
              <CellRendererApprovalStatus :params="{account_approval_status: props.row.account_approval_status}" />
            </span>

            <span v-else-if="props.column.field === 'actions'">

              <CellRendererActions
                :params="{
                  account_approval_status: props.row.account_approval_status,
                  email_address: props.row.email_address,
                  id: props.row.user_id
                }"
              />
            </span>

            <!-- Column: Common -->
            <span v-else>

              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

        </vue-good-table>
      </b-card>
    </b-overlay>

    <b-modal
      v-model="dateRangeFilter"
      centered
      hide-footer
      title="Choose the desired date range to filter the data"
      class="bg-danger"
      size="lg"
    >
      <v-date-picker
        v-model="startEndDate"
        mode="date"
        :masks="masks"
        color="orange"
        :columns="2"
        is-range
        is-expanded
      />
    </b-modal>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import { dateRangePickerMixins } from '@/components/date-range-picker/dateRangePickerMixins'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  VBTooltip,
  BOverlay,
  BFormCheckbox,
  BAlert,
  BFormInvalidFeedback,
  BPagination,
  BDropdown, BDropdownItem, BDropdownDivider,
  BFormRow,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { onResizeMixins } from '@/components/mixins/onResizeMixins'
import '@/assets/scss/themes/materialize/tabulator_materialize.scss'

export default {
  components: {
    BCard,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BOverlay,
    BFormCheckbox,
    BAlert,
    BPagination,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BFormRow,
    BFormInvalidFeedback,
    VueGoodTable,
    flatPickr,
  },
  mixins: [onResizeMixins, dateRangePickerMixins],
  data() {
    return {
      isLoading: false,
      blur_check: 'blur',
      records: [],
      searchQuery: '',
      maxPageNumbers: 7,

      columnDefs: [
        {
          label: 'Full Name',
          field: 'fullname',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
          cellRendererFramework: 'CellRendererLink',
        },
        {
          label: 'Added On',
          field: 'created_on',
          width: '320px',
          type: 'date',
          dateInputFormat: 'do MMM yyyy HH:mm', // expects 2018-03-16
          dateOutputFormat: 'do MMM yyyy HH:mm', // outputs Mar 16th 2018
          tdClass: 'text-left',
          thClass: 'text-left',
          filterOptions: {
            customFilter: true,
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
          valueFormatter: params => this.$formatDate(params.value),
        },
        {
          label: 'Log Type',
          field: 'type',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Email Address',
          field: 'email_address',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Details',
          field: 'description',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },
      ],

      // vue-good-table
      serverParams: {
        columnFilters: {},
        sort: {},
        page: 1,
        perPage: 10,
      },
      totalRecords: 0,
      dataRows: [],
      created_on_filter: null,

      configPicker: {
        altInput: true,
        enableTime: false,
      },
    }
  },

  computed: {
  },
  watch: {
    records() {
      this.isLoading = false
    },
    '$route.name': function (val) {
      this.checkBeforeProceed()
    },
    isMobile(val) {
      if (val) {
        this.setColumnPinnedState('email', null)
      } else {
        this.setColumnPinnedState('email', 'left')
      }
    },
  },
  mounted() {
    this.checkBeforeProceed()
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
      this.checkBeforeProceed()
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
    },
    onSortChange(params) {
      if (params[0]) {
        const fieldName = params[0].field
        const sortDetails = { }
        sortDetails[fieldName] = params[0].type
        this.updateParams({ sort: sortDetails })
      }
    },
    onColumnFilter(params) {
      this.updateParams(params)
    },
    updateFilters(column, value) {
      const currentFilters = this.serverParams.columnFilters
      currentFilters[column] = value
      this.updateParams({ columnFilters: currentFilters })
    },

    checkBeforeProceed() {
      if (this.check_rights()) {
        this.blur_check = ''
        this.extractLogs()
      } else {
        this.blur_check = 'blur'
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Inadequate permission',
              icon: 'CheckCircleIcon',
              text: 'You dont have enough rights to view the data in this section',
              variant: 'danger',
              position: 'center',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val)
    },
    check_rights() {
      // let checker = (arr, target) => target.every((v) => arr.includes(v));
      const { permissions } = this.$store.state.auth.ActiveUser
      const currentPagePermission = permissions.filter(item => item.section === 'Audit Log')[0]
      const currentPermission = currentPagePermission.read
      if (currentPermission) {
        return true
      }
      return true
    },
    extractLogs() {
      this.isLoading = true
      const routeName = this.$route.name
      const { token } = this.$store.state.auth.ActiveUser
      this.$http.defaults.headers.common.Authorization = `Token ${token}`

      let query = ''
      query = `?params=${JSON.stringify(this.serverParams)}`

      this.$http
        .get(`/api/logs/${routeName}${query}`)
        .then(response => {
          const output = response.data
          this.records = []
          if (output.success) {
            this.records = output.data
            this.totalRecords = output.totalRecords

            // if (this.isMobile) {
            //   this.setColumnPinnedState('email', null)
            // }

            // setTimeout(() => {
            //   this.calculateRowsToFitInTable()
            // }, 200)
          } else {
            this.isLoading = false
          }
        })
        .catch(error => {
          this.$handleErrorResponse(error)
        })
    },
  },
}
</script>
<style type="scss" scoped>
.blur {
  filter: blur(16px);
}
.tabulator{
  font-size: 16px;
  font-family: sans-serif;
  font-weight: 500;
  border-style: solid;
  border-width: 1px;
  border-color: gray;
  border-radius: 6px;
}
#table{

}

</style>
